import * as React from 'react'
import styled from '@emotion/styled'

import { widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

export const Col = (props: {sm: number, children?: any}) => (
	<div style={{width: `${props.sm * (90 / 12)}%`, alignSelf: 'flex-start'}}>
		{props.children}
	</div>
);
