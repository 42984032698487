//java -jar ../swagger-codegen-cli.jar generate -i https://dasvc.azurewebsites.net/api/Swagger?code=zQi/5k2f0S/CAg10Mzi/pvF4UdJf1YFdm9JNRFIqnr6shVk0a8WEeQ== -l typescript-fetch -o ./src/api

import * as querystring from "querystring";
import * as url from "url";

require("portable-fetch");
//import * as assign from "core-js/library/fn/object/assign";
const assign = require('object-assign');
const isomorphicFetch = fetch;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = (() => {
  return "https://mwdasvc.azurewebsites.net".replace(/\/+$/, "");
  if (typeof window !== 'undefined' && window.location.host !== 'localhost:8000') {
    return "https://mwdasvc.azurewebsites.net".replace(/\/+$/, "");
  }
  return "http://localhost:7071".replace(/\/+$/, "");
})();

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface AccountValidationModel {
    "accountNumber"?: string;
    "accountName"?: string;
}

export interface AccountValidationResult {
    "success"?: boolean;
    "registrations"?: Array<DirectAlertTableEntity>;
}

export interface ApiRegistrations {
    "registrations"?: Array<DirectAlertTableEntity>;
    "recordsAffected"?: number;
    "success"?: boolean;
}

export interface ApiResultAccountValidationResult {
    "value"?: AccountValidationResult;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface ApiResultApiRegistrations {
    "value"?: ApiRegistrations;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface ApiResultListExportRecord {
    "value"?: Array<ExportRecord>;
    "success"?: boolean;
    "message"?: string;
    "stackTrace"?: Array<Stack>;
}

export interface DirectAlertTableEntity {
    "accountNumber"?: string;
    "accountName"?: string;
    "zipCode"?: string;
    "cellPhone"?: string;
    "homePhone"?: string;
    "workPhone"?: string;
    "email"?: string;
    "wantCellSMS"?: boolean;
    "wantCellCall"?: boolean;
    "wantHomeCall"?: boolean;
    "wantWorkCall"?: boolean;
    "wantEmergencyEmail"?: boolean;
    "wantEmail"?: boolean;
    "cDate"?: Date;
    "altPhone"?: string;
    "expiry"?: Date;
    "historyRow"?: string;
    "historyPartition"?: string;
    "partitionKey"?: string;
    "rowKey"?: string;
    "timestamp"?: Date;
    "eTag"?: string;
}

export interface ExportRecord {
    "id"?: number;
    "exportDate"?: Date;
    "fromDate"?: Date;
    "toDate"?: Date;
}

export interface RegistrationResult {
    "success"?: boolean;
    "confirmation"?: DirectAlertTableEntity;
}

export interface SearchQuery {
    "query"?: string;
    "skip"?: number;
    "take"?: number;
    "minDate"?: string;
    "maxDate"?: string;
    "recordExport"?: boolean;
}

export interface Stack {
    "in"?: string;
    "at"?: string;
}



/**
 * DirectAlertApi - fetch parameter creator
 */
export const DirectAlertApiFetchParamCreator = {
    /**
     *
     * @summary DirectAlertAdminImport
     * @param req
     * @param authToken
     */
    directAlertAdminImportPOST(params: {  "req"?: ApiRegistrations; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/dAdmin/import`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary DirectAlertAdminSearch
     * @param req
     * @param authToken
     */
    directAlertAdminSearchPOST(params: {  "req"?: SearchQuery; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/dAdmin/search`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary GetExportHistory
     * @param authToken
     */
    getExportHistoryGET(params: {  "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/dAdmin/exportHistory`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary UpdateRegistration
     * @param req
     * @param authToken
     */
    updateRegistrationPOST(params: {  "req"?: DirectAlertTableEntity; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/UpdateRegistration`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     *
     * @summary ValidateRegistration
     * @param req
     * @param authToken
     */
    validateRegistrationPOST(params: {  "req"?: AccountValidationModel; "authToken"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/ValidateRegistration`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "authToken": params["authToken"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["req"]) {
            fetchOptions.body = JSON.stringify(params["req"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DirectAlertApi - functional programming interface
 */
export const DirectAlertApiFp = {
    /**
     *
     * @summary DirectAlertAdminImport
     * @param req
     * @param authToken
     */
    directAlertAdminImportPOST(params: { "req"?: ApiRegistrations; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiRegistrations> {
        const fetchArgs = DirectAlertApiFetchParamCreator.directAlertAdminImportPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary DirectAlertAdminSearch
     * @param req
     * @param authToken
     */
    directAlertAdminSearchPOST(params: { "req"?: SearchQuery; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultApiRegistrations> {
        const fetchArgs = DirectAlertApiFetchParamCreator.directAlertAdminSearchPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary GetExportHistory
     * @param authToken
     */
    getExportHistoryGET(params: { "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultListExportRecord> {
        const fetchArgs = DirectAlertApiFetchParamCreator.getExportHistoryGET(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary UpdateRegistration
     * @param req
     * @param authToken
     */
    updateRegistrationPOST(params: { "req"?: DirectAlertTableEntity; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegistrationResult> {
        const fetchArgs = DirectAlertApiFetchParamCreator.updateRegistrationPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     *
     * @summary ValidateRegistration
     * @param req
     * @param authToken
     */
    validateRegistrationPOST(params: { "req"?: AccountValidationModel; "authToken"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiResultAccountValidationResult> {
        const fetchArgs = DirectAlertApiFetchParamCreator.validateRegistrationPOST(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DirectAlertApi - object-oriented interface
 */
export class DirectAlertApi extends BaseAPI {
    /**
     *
     * @summary DirectAlertAdminImport
     * @param req
     * @param authToken
     */
    directAlertAdminImportPOST(params: {  "req"?: ApiRegistrations; "authToken"?: string; }, options?: any) {
        return DirectAlertApiFp.directAlertAdminImportPOST(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary DirectAlertAdminSearch
     * @param req
     * @param authToken
     */
    directAlertAdminSearchPOST(params: {  "req"?: SearchQuery; "authToken"?: string; }, options?: any) {
        return DirectAlertApiFp.directAlertAdminSearchPOST(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary GetExportHistory
     * @param authToken
     */
    getExportHistoryGET(params: {  "authToken"?: string; }, options?: any) {
        return DirectAlertApiFp.getExportHistoryGET(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary UpdateRegistration
     * @param req
     * @param authToken
     */
    updateRegistrationPOST(params: {  "req"?: DirectAlertTableEntity; "authToken"?: string; }, options?: any) {
        return DirectAlertApiFp.updateRegistrationPOST(params, options)(this.fetch, this.basePath);
    }
    /**
     *
     * @summary ValidateRegistration
     * @param req
     * @param authToken
     */
    validateRegistrationPOST(params: {  "req"?: AccountValidationModel; "authToken"?: string; }, options?: any) {
        return DirectAlertApiFp.validateRegistrationPOST(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DirectAlertApi - factory interface
 */
export const DirectAlertApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary DirectAlertAdminImport
         * @param req
         * @param authToken
         */
        directAlertAdminImportPOST(params: {  "req"?: ApiRegistrations; "authToken"?: string; }, options?: any) {
            return DirectAlertApiFp.directAlertAdminImportPOST(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary DirectAlertAdminSearch
         * @param req
         * @param authToken
         */
        directAlertAdminSearchPOST(params: {  "req"?: SearchQuery; "authToken"?: string; }, options?: any) {
            return DirectAlertApiFp.directAlertAdminSearchPOST(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary GetExportHistory
         * @param authToken
         */
        getExportHistoryGET(params: {  "authToken"?: string; }, options?: any) {
            return DirectAlertApiFp.getExportHistoryGET(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary UpdateRegistration
         * @param req
         * @param authToken
         */
        updateRegistrationPOST(params: {  "req"?: DirectAlertTableEntity; "authToken"?: string; }, options?: any) {
            return DirectAlertApiFp.updateRegistrationPOST(params, options)(fetch, basePath);
        },
        /**
         *
         * @summary ValidateRegistration
         * @param req
         * @param authToken
         */
        validateRegistrationPOST(params: {  "req"?: AccountValidationModel; "authToken"?: string; }, options?: any) {
            return DirectAlertApiFp.validateRegistrationPOST(params, options)(fetch, basePath);
        },
    };
};

